body {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 60% 50%;
  background-attachment: fixed;
  padding-top: 40px;
  padding-bottom: 24px;

  @media (min-width: $screen-md-min) {
    background-position: right top;
  }

  &::before {
    content: ' ';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 20%;
    background: linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
    box-sizing: border-box;
  }

  &::after {
    content: ' ';
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 10%;
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.6) 100%);
    z-index: -1;
  }
}