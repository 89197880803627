header.banner {
  margin-bottom: 50px;
}

.brand {
  @include make-xs-column(8);
  @include make-md-column(9);
  opacity: 0.9;

  @media (min-width: $screen-sm-min) {
    margin-top: 20px;
  }

  &:hover {
    opacity: 1;
  }
}
