// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$body-bg:               #000;

$brand-primary:         #fff;
$brand-secondary:       #FECF43;

$text-color:            #fff;

$font-family-sans-serif:  "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana, sans-serif;

$font-size-base:          16px;
$font-size-large:         ceil(($font-size-base * 1.125)); // ~18px
$font-size-small:         ceil(($font-size-base * 0.875)); // ~14px

$font-size-h1:            floor(($font-size-base * 2.25));
$font-size-h2:            floor(($font-size-base * 2));
$font-size-h3:            ceil(($font-size-base * 1.7));
$font-size-h4:            ceil(($font-size-base * 1.25));
$font-size-h5:            $font-size-base;
$font-size-h6:            ceil(($font-size-base * 0.85));

$line-height-base:        1.5625;

$btn-primary-color:       #000;
$btn-primary-border:      $brand-primary;