footer.content-info {
  padding-top: 20px;
  text-align: center;
  font-size: 12px;
  line-height: 1.1;

  ul {
    display: inline-block;
    vertical-align: bottom;
    padding: 0;

    @media (min-width: $screen-sm-min) {
      margin-bottom: 0;
      padding: 50px 0 0 0;
    }

    li {
      display: block;
      text-transform: uppercase;

      @media (min-width: $screen-xs) {
        display: inline-block;

        &:not(:last-child) {
          padding-right: 10px;
          margin-right: 10px;
          border-right: 1px solid #fff;
        }
      }
    }
  }

  .hollywood-records {
    display: block;
    margin: 0 auto;

    @media (min-width: $screen-sm-min) {
      float: right;
    }
  }
}
