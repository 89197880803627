.page-header {
  margin-top: 0;

  h1 {
    margin-top: 10px;
    margin-bottom: 0;
  }
}

.content-box {
  background: rgba(0, 0, 0, 0.6);
  padding: 20px;

  a {
    color: $brand-secondary;
  }
}

.spotify-block {
  @include make-sm-column(10);
  @include make-md-column(8);
  @include make-lg-column(7);
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  position: relative;

  @media (min-width: $screen-sm-min) {
    padding-top: 30px;
  }
}

.media-block {
  @include make-sm-column(10);
  @include make-md-column(8);
  @include make-lg-column(7);
  padding-top: 280px;

  @media (min-width: $screen-sm-min) {
    padding-top: 50px;
  }

  .media-badges {
    list-style: none;
    padding: 10px 0 0 0;
    margin: 0;
    text-align: center;

    li {
      display: inline-block;
      margin-bottom: 10px;

      img {
        max-height: 40px;
        width: auto;
      }
    }
  }
}

.video-grid {
  @include make-row;
  margin: 0 -3px;

  .video-item {
    display: block;
    @include make-sm-column(6);
    padding: 3px;

    .inner {
      padding-bottom: 56.25%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      position: relative;

      .fa {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        font-size: 4em;
        color: rgba(255, 255, 255, 0.5);
      }

      &:hover {
        opacity: 0.9;

        .fa {
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }
  }
}