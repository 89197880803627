.nav-wrapper {
  padding-top: 10px;
  clear: both;
  position: relative;
  z-index: 10;

  .menu-toggle {
    float: right;
    color: #000;
    text-transform: uppercase;

    @media (min-width: $screen-sm-min) {
      display: none;
    }
  }

  .navigation {
    display: none;
    padding: 10px;
    margin-top: 10px;
    top: 55px;
    min-width: 200px;
    border-radius: 4px;
    background: #fff;
    text-align: center;
    right: 0;
    position: absolute;
    z-index: 2;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2);

    @media (min-width: $screen-sm-min) {
      display: block;
      top: 0;
      padding: 0;
      background: none;
      position: relative;
      float: right;
      box-shadow: none;
      text-align: right;
      min-width: auto;
    }

    @media (min-width: $screen-lg-min) {
      font-size: 16px;
    }

    .menu-item {
      list-style-type: none;
      display: block;
      border-bottom: 1px solid $gray-lighter;
      position: relative;

      &.active {
        a {
          font-weight: bold;
        }
      }

      @media (min-width: $screen-sm-min) {
        border: none;

        &.active {
          a {
            color: #fff;
          }
        }

      }

      &:last-child {
        border-bottom: none;
      }

      a {
        color: #000;
        display: block;
        padding: 6px;
        text-transform: uppercase;

        @media (min-width: $screen-sm-min) {
          display: block;
          color: #fff;
          padding: 8px 0;
        }
      }
    }
  }
}
