.socials {
  list-style: none;
  text-align: right;
  padding-left: 0;

  .social {
    display: inline-block;
    margin-left: 10px;

    @media (min-width: $screen-md-min) {
      margin-left: 20px;
    }

    a {
      font-size: 1.8em;

      @media (min-width: $screen-md-min) {
        font-size: 2.3em;
      }

      @media (min-width: $screen-lg-min) {
        font-size: 2.5em;
      }
    }

    .musically {
      width: 27px;
      height: 27px;
      position: relative;
      bottom: -2px;
      background: url('../images/musically-logo.svg');
      background-size: contain;
      
      @media (min-width: $screen-md-min) {
        width: 35px;
        height: 35px;
      }
    }
  }
}
